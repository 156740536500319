<template>
  <div class="linkedin grid grid-cols-3 gap-4">
    <div class="col-start-1 row-start-1">
      <div class="logo logo-linkedin"></div>
    </div>
    <div class="col-start-2 row-start-2">
      <div class="card">
        <h2 class="mb-5 color-linkedin">Identifiants Linkedin</h2>
        <form>
          <input type="username" name="username" v-model="username">
          <input type="password" name="password" v-model="password">
          <div class="button linkedin mb-5" @click="saveUserLinkedin">
            <span>Enregistrer</span>
          </div>
          <div class="button cancel" @click="removeUserLinkedin">
            <span>Supprimer</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios"
import Swal from "sweetalert2"

export default {
  name: 'Linkedin',
  data() {
    return {
      username: null,
      password: null
    }
  },
  methods: {
    saveUserLinkedin() {
      let that = this
      if (this.username && this.password) {
        axios
          .post(process.env.VUE_APP_BO + '/linkedins', {
            username: that.username,
            password: that.password
          }, {headers: {
             'Access-Control-Allow-Origin': '*',
             'Content-type': 'application/json',
            }
          })
          .then(response => {
            console.log(response)
            that.username = null
            that.password = null
            Swal.fire({
              title: "Succès !", 
              text: "Vos identifiants ont bien été encryptés dans notre base de données.", 
              icon: "success",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          })
          .catch(error => {
            console.log(error)
            Swal.fire({
              title: "Erreur !", 
              text: "Il semble que vos identifiants soient déjà enregistrés.", 
              icon: "error",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          })
      }
    },
    removeUserLinkedin() {
      let that = this
      if (this.username && this.password) {
        axios
          .post(process.env.VUE_APP_BO + '/linkedins-remove', {
            username: that.username,
            password: that.password
          }, {headers: {
             'Access-Control-Allow-Origin': '*',
             'Content-type': 'application/json',
            }
          })
          .then(response => {
            console.log(response)
            that.username = null
            that.password = null
            Swal.fire({
              title: "Succès !", 
              text: "Vos identifiants ont bien été retiré de notre base de données.", 
              icon: "success",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          })
          .catch(error => {
            console.log(error)
            Swal.fire({
              title: "Erreur !", 
              text: "Il semble que vos identifiants ne soient pas reconnus.", 
              icon: "error",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          })
      }
    }
  }
}
</script>
