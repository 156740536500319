import axios from "axios"

const users = {
  state: {
    currentUser: false,
    currentAdmin: false,
    users: [],
    linkedinUsers: [],
    twitterUsers: [],
    inReloadLinkedin: [],
    inReloadTwitter: []
  },
  getters: {
    getUsers: state => state.users,
    getLinkedinUsers: state => state.linkedinUsers,
    getTwitterUsers: state => state.twitterUsers,
    getReloadLinkedin: state => state.inReloadLinkedin,
    getReloadTwitter: state => state.inReloadTwitter,
    getCurrentUser: state => state.currentUser,
    getCurrentAdmin: state => state.currentAdmin
  },
  actions: {
    safeConnexion({ commit }, password) {
      return new Promise((resolve, reject) => {
        if (password == process.env.VUE_APP_PASSWORD) {
          commit('setCurrentUser')
          resolve('user')
        } else if (password == process.env.VUE_APP_PASSWORD_ADMIN) {
          commit('setCurrentAdmin')
          resolve('admin')
        } else {
          commit('clearCurrentUser')
          reject('unknown')
        }
      })
    },
    getAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_BO + "/users")
          .then(response => {
            console.log(response)
            commit('setUsers', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    getAllLinkedinUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_BO + "/linkedins")
          .then(response => {
            console.log(response)
            commit('setLinkedinUsers', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    getAllTwitterUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_BO + "/twitters")
          .then(response => {
            console.log(response)
            commit('setTwitterUsers', response.data)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    reloadLinkedinCookie({ commit, dispatch }, linkedin) {
      return new Promise((resolve, reject) => {
        commit('pushInReloadLinkedin', linkedin.id)
        axios
          .post(process.env.VUE_APP_BO + "/linkedin-reload", {
            username: linkedin.username
          }, {headers: {
             'Access-Control-Allow-Origin': '*',
             'Content-type': 'application/json',
            }
          })
          .then(response => {
            console.log(response)
            dispatch('getAllLinkedinUsers')
            commit('popInReloadLinkedin', linkedin.id)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    reloadTwitterCookie({ commit, dispatch }, twitter) {
      return new Promise((resolve, reject) => {
        commit('pushInReloadTwitter', twitter.id)
        axios
          .post(process.env.VUE_APP_BO + "/twitter-reload", {
            username: twitter.username
          }, {headers: {
             'Access-Control-Allow-Origin': '*',
             'Content-type': 'application/json',
            }
          })
          .then(response => {
            console.log(response)
            dispatch('getAllTwitterUsers')
            commit('popInReloadTwitter', twitter.id)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    }
  },
  mutations: {
    clearCurrentUser(state) {
      state.currentUser = false
      state.currentAdmin = false
    },
    setCurrentUser(state) {
      state.currentUser = true
      state.currentAdmin = false
    },
    setCurrentAdmin(state) {
      state.currentUser = true
      state.currentAdmin = true
    },
    setUsers(state, users) {
      state.users = users
    },
    setLinkedinUsers(state, users) {
      state.linkedinUsers = users
    },
    setTwitterUsers(state, users) {
      state.twitterUsers = users
    },
    pushInReloadLinkedin(state, id) {
      state.inReloadLinkedin.push(id)
    },
    popInReloadLinkedin(state, id) {
      state.inReloadLinkedin = _.remove(state.inReloadLinkedin, function(n) {
        return n.id == id
      })
    },
    pushInReloadTwitter(state, id) {
      state.inReloadTwitter.push(id)
    },
    popInReloadTwitter(state, id) {
      state.inReloadTwitter = _.remove(state.inReloadTwitter, function(n) {
        return n.id == id
      })
    }
  }
}

export default users
