<template>
  <div class="home">
    <h1>Connexion avec linkedin ou avec twitter</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  }
}
</script>
