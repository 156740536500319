<template>
  <div class="grid grid-cols-2 gap-5">
    <div class="col-start-1 row-start-2">
      <div class="logo logo-linkedin"></div>
    </div>
    <div class="col-start-1 row-start-3">
      <div class="table">
        <div class="table-header color-linkedin uppercase bold">
          <div class="col col-2">Username</div>
          <div class="col col-5">Cookie</div>
          <div class="col col-3">Actions</div>
        </div>
        <div class="table-body">
          <div class="linkedin-user" v-for="linkedin_user in linkedin_users">
            <div class="col col-2">{{ linkedin_user.username }}</div>
            <div class="col col-5 cookie-container">
              <div class="td-cookie">{{ linkedin_user.cookie }}</div>
            </div>
            <div class="col col-3">
              <div class="buttons">
                <div class="button small-button reload" @click="reloadLinkedinCookie(linkedin_user)">
                  <span v-if="getReloadLinkedin.includes(linkedin_user.id)">
                    <span>Processing</span>
                  </span>
                  <span v-else>Regénérer</span>
                </div>
                <div class="button small-button copy" @click="copy(linkedin_user.cookie)">
                  <span>Copier</span>
                </div>
                <div class="button small-button cancel" @click="removeUserLinkedin(linkedin_user)">
                  <span>Supprimer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-start-2 row-start-2">
      <div class="logo logo-twitter"></div>
    </div>
    <div class="col-start-2 row-start-3">
      <div class="table">
        <div class="table-header color-twitter uppercase bold">
          <div class="col col-2">Username</div>
          <div class="col col-5">Cookie</div>
          <div class="col col-3">Actions</div>
        </div>
        <div class="table-body">
          <div class="linkedin-user" v-for="twitter_user in twitter_users">
            <div class="col col-2">{{ twitter_user.username }}</div>
            <div class="col col-5 cookie-container">
              <div class="td-cookie">{{ twitter_user.cookie }}</div>
            </div>
            <div class="col col-3">
              <div class="buttons">
                <div class="button small-button reload" @click="reloadTwitterCookie(twitter_user)">
                  <span v-if="getReloadTwitter.includes(twitter_user.id)">
                    <span>Processing</span>
                  </span>
                  <span v-else>Regénérer</span>
                </div>
                <div class="button small-button copy" @click="copy(twitter_user.cookie)">
                  <span>Copier</span>
                </div>
                <div class="button small-button cancel" @click="removeUserTwitter(twitter_user)">
                  <span>Supprimer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters, mapActions } from 'vuex'
import Swal from "sweetalert2"

export default {
  name: 'users',
  data() {
    return {
      t: "JTDdznbAZJHGCDAZD112345"
    }
  },
  computed: {
    linkedin_users() {
      return this.$store.getters.getLinkedinUsers
    },
    twitter_users() {
      return this.$store.getters.getTwitterUsers
    },
    currentAdmin() {
      return this.$store.getters.getCurrentAdmin
    },
    ...mapGetters(['getReloadLinkedin', 'getReloadTwitter'])
  },
  mounted() {
    this.getAllLinkedinUsers()
    this.getAllTwitterUsers()
    if (!this.currentAdmin) {
      this.$router.push('linkedin')
    }
  },
  methods: {
    ...mapActions(['getAllLinkedinUsers', 'getAllTwitterUsers', 'reloadLinkedinCookie', 'reloadTwitterCookie']),
    copy(s) {
      navigator.clipboard.writeText(s)
    },
    removeUserLinkedin(linkedin) {
      axios
        .post(process.env.VUE_APP_BO + '/linkedins-remove-from-bo', {
          username: linkedin.username
        }, {headers: {
           'Access-Control-Allow-Origin': '*',
           'Content-type': 'application/json',
          }
        })
        .then(response => {
          console.log(response)
          Swal.fire({
            title: "Succès !", 
            text: "Cet identifiant a bien été retiré de notre base de données.", 
            icon: "success",
            confirmButtonColor: "#0A66C1",
            padding: "2rem"
          })
          this.getAllLinkedinUsers()
        })
        .catch(error => {
          console.log(error)
          Swal.fire({
            title: "Erreur !", 
            text: "Il semble que cet identifiant ne soit pas reconnu.", 
            icon: "error",
            confirmButtonColor: "#0A66C1",
            padding: "2rem"
          })
        })
    },
    removeUserTwitter(twitter) {
      axios
        .post(process.env.VUE_APP_BO + '/twitters-remove-from-bo', {
          username: twitter.username
        }, {headers: {
           'Access-Control-Allow-Origin': '*',
           'Content-type': 'application/json',
          }
        })
        .then(response => {
          console.log(response)
          Swal.fire({
            title: "Succès !", 
            text: "Cet identifiant a bien été retiré de notre base de données.", 
            icon: "success",
            confirmButtonColor: "#0A66C1",
            padding: "2rem"
          })
          this.getAllTwitterUsers()
        })
        .catch(error => {
          console.log(error)
          Swal.fire({
            title: "Erreur !", 
            text: "Il semble que cet identifiant ne soit pas reconnu.", 
            icon: "error",
            confirmButtonColor: "#0A66C1",
            padding: "2rem"
          })
        })
    }
  }
}
</script>