<template>
  <div id="app">
    <h1>QUALITARGET by 10h11</h1>
    <div id="content" v-if="currentUser || currentAdmin">
      <div id="nav">
        <router-link class="color-linkedin" to="/linkedin">&#x021D2; LinkedIn</router-link>
        <router-link class="color-twitter" to="/twitter">&#x021D2; Twitter</router-link>
        <router-link class="color-error" to="/users" v-if="currentAdmin">&#x021D2; Utilisateurs</router-link>
      </div>
      <router-view/>
    </div>
    <div v-else class="grid grid-cols-3 gap-4">
      <div class="col-start-2 row-start-1">
        <h2>Vous entrez dans un espace sécurisé</h2>
      </div>
      <div class="col-start-2 row-start-2">
        <form>
          <input type="password" name="password" placeholder="Renseigner un mot de passe" v-model="password">
          <div class="button linkedin" @click="trySafeConnexion()">
            <span>Connexion</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from "sweetalert2"

export default {
  name: 'app',
  data() {
    return {
      password: null
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.getCurrentUser
    },
    currentAdmin() {
      return this.$store.getters.getCurrentAdmin
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions(['safeConnexion']),
    trySafeConnexion() {
      this.safeConnexion(this.password)
        .then(response => {
          console.log('response', response)
          if (response == 'admin') {
            Swal.fire({
              title: "Succès !", 
              text: "Hello Marius !", 
              icon: "success",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          } else {
            Swal.fire({
              title: "Succès !", 
              text: "Bienvenue sur qualitarget by 10h11 !", 
              icon: "success",
              confirmButtonColor: "#0A66C1",
              padding: "2rem"
            })
          }
        })
        .catch(error => {
          console.log('error', error)
          Swal.fire({
            title: "Erreur !", 
            text: "Mot de passe incorrect.", 
            icon: "error",
            confirmButtonColor: "#0A66C1",
            padding: "2rem"
          })
        })
    }
  }
}
</script>

<style lang="stylus">
  @import "assets/style/app.styl"
</style>
