import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'

import './index.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2'

Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#0A66C1",
  cancelButtonColor: '#DC2626'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
